import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

import './resume.css';

export default ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description

  return (
    <Layout>
      <SEO title="resume" />
      <Helmet
        htmlAttributes={{ lang: "en" }}
        meta={[{ name: "description", content: siteDescription }]}
        title={siteTitle}
      />
      <main>
        {/* 联系方式 */}
        <section>
          <h1>联系方式</h1>
          <div class="boxes">
            <div class="box bg-gray-lightest p-y-0">
              <div class="row g-32">
                <div class="col-4 p-y-8 b-r-1">● 手机：189********</div>
                <div class="col-4 p-y-8 b-r-1">● Email：<a href="mailto:shanejix@hotmail.com">shanejix@hotmail.com</a></div>
                <div class="col-4 p-y-8">● QQ/微信：987******</div>
              </div>
            </div>
          </div>
        </section>

        {/* 个人信息 */}
        <section>
          <h1>个人信息</h1>
          <div class="boxes">
            <div class="box bg-gray-lightest p-y-0">
              <div class="row g-32">
                <div class="col-4 p-y-8 b-r-1">● shanejix | 男 </div>
                <div class="col-4 p-y-8 b-r-1">● 本科 | 哈尔滨**大学 </div>
                <div class="col-4 p-y-8">● 应聘 Web 前端工程师 | 3 年工作经验 </div>
              </div>
            </div>
            <div class="box bg-gray-lightest">
              ● Github：
              <a href="https://github.com/shanejix" target="_blank"
                class="text-gray-dark">https://github.com/shanejix</a>
            </div>
            <div class="box bg-gray-lightest">
              ● 技术博客：
              <a href="https://shanejix.com" target="_blank"
                class="text-gray-dark">https://shanejix.com</a>
            </div>
            <div class="box bg-gray-lightest">
              ● Cnblogs：
              <a href="https://www.cnblogs.com/shanejix" target="_blank"
                class="text-gray-dark">https://www.cnblogs.com/shanejix</a>
            </div>

          </div>
        </section>

        {/* 技术优势 */}
        <section>
          <h1>技术优势</h1>
          <div class="boxes">
            <div class="box bg-gray-lightest">
              ● 编写更加合理的 HTML 以及规范化的 CSS、具有像素级还原设计稿的能力
            </div>
            <div class="box bg-gray-lightest">
              ● 熟悉 JavaScript / TypeScript 语言、掌握 ES6+ 语法、持续关注 ECMAScript 标准
            </div>
            <div class="box bg-gray-lightest">
              ● 了解 HTTP 协议、能熟练运用 Promise 优化项目中的各种异步逻辑
            </div>
            <div class="box bg-gray-lightest">
              ● 在项目中使用过 Webpack 等打包工具、熟悉前端工程化构建流程
            </div>
            <div class="box bg-gray-lightest">
              ● 熟悉 React 相关技术栈、并了解一部分框架的底层原理、能在自己的代码中借鉴其思想和风格
            </div>
            <div class="box bg-gray-lightest">
              ● 兴趣广泛、略懂后端、可以写点 Node
            </div>
          </div>
        </section>

        {/* 开源项目 */}
        <section>
          <h1>开源项目</h1>
          <div class="boxes">
            <div class="box bg-gray-lightest">
              ● shanejix.github.io（基于 Gatsby 的专注写作的个人博客、一处更新多端同步）：
              <a
                href="https://github.com/shanejix/shanejix.github.io"
                target="_blank"
                class="text-gray-dark"
              >
                https://github.com/shanejix/shanejix.github.io
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● @block-org/block-cli（基于 Webpack 和 Babel 开发的 UI 组件库工具链）：
              <a
                href="https://github.com/block-org/block-cli"
                target="_blank"
                class="text-gray-dark"
              >
                https://github.com/block-org/block-cli
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● @underspare/eslint-config（项目 ESLint 配置规范、个性化 ESLint 规则参考）：
              <a
                href="https://github.com/underspare/eslint-config"
                target="_blank"
                class="text-gray-dark"
              >
                https://github.com/underspare/eslint-config
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● algorithm-and-data-structure（算法学习、记录 leetcode 等）：
              <a
                href="https://github.com/shanejix/algorithm-and-data-structure"
                target="_blank"
                class="text-gray-dark"
              >
                https://github.com/shanejix/algorithm-and-data-structure
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● crazy-cat（圈小猫、基础搜索算法探索）：
              <a
                href="https://github.com/shanejix/crazy-cat"
                target="_blank"
                class="text-gray-dark"
              >
                https://github.com/shanejix/crazy-cat
              </a>
            </div>
          </div>
        </section>

        {/* 技术文章 */}
        <section>
          <h1>技术文章</h1>
          <div class="boxes">
            <div class="box bg-gray-lightest">
              ● &nbsp;
              <a
                href="https://shanejix.com/posts/Vanilla%20JavaScript%20Inherited/"
                target="_blank"
                class="text-gray-dark"
              >
                《 Vanilla JavaScript Inherited 》
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● &nbsp;
              <a
                href="https://www.shanejix.com/posts/%E4%BB%8E%200%20%E5%88%B0%201%20%E6%90%AD%E5%BB%BA%20React%20UI%20%E7%BB%84%E4%BB%B6%E5%BA%93/"
                target="_blank"
                class="text-gray-dark"
              >
                《 Build React UI component library from 0 to 1 》
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● &nbsp;
              <a
                href="https://www.shanejix.com/posts/Promises%20implementation%20with%20ES6%20class/"
                target="_blank"
                class="text-gray-dark"
              >
                《 Promises A+ implementation with ES6 class 》
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● &nbsp;
              <a
                href="https://www.shanejix.com/posts/TypeScript%20%E4%B8%AD%E7%9A%84%E5%8D%95%E4%BE%8B%E6%A8%A1%E5%BC%8F/"
                target="_blank"
                class="text-gray-dark"
              >
                《 Singleton Pattern in TypeScript 》
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● &nbsp;
              <a
                href="https://shanejix.com/posts/%E4%BB%8E%200%20%E5%88%B0%201%20%E5%85%A5%E9%97%A8%E5%8A%A8%E6%80%81%E8%A7%84%E5%88%92/"
                target="_blank"
                class="text-gray-dark"
              >
                《 Dynamic Programming 》
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● &nbsp;
              <a
                href="https://shanejix.com/posts/%E5%A6%82%E4%BD%95%E4%BD%BF%E7%94%A8%20JavaScript%20%E5%AE%9E%E7%8E%B0%E4%BA%8C%E5%8F%89%E6%A0%91%EF%BC%8C%E4%BA%8C%E5%8F%89%E5%B9%B3%E8%A1%A1%E6%A0%91%E5%92%8C%E7%BA%A2%E9%BB%91%E6%A0%91/"
                target="_blank"
                class="text-gray-dark"
              >
                《 How To Use Javascript To Implement A Binary Search Tree And More 》
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● &nbsp;
              <a
                href="https://www.shanejix.com/posts/Implementation%20Axios/"
                target="_blank"
                class="text-gray-dark"
              >
                《 Implementation Axios 》
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● &nbsp;
              <a
                href="https://www.shanejix.com/posts/Good%20Git%20Commit%20Message/"
                target="_blank"
                class="text-gray-dark"
              >
                《 Good Git Commit Message 》
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● &nbsp;
              <a
                href="https://www.shanejix.com/posts/A%20Rollover%20Scene%20Caused%20By%20%60node_modules%60/"
                target="_blank"
                class="text-gray-dark"
              >
                《 A Rollover Scene Caused By `node_modules` 》
              </a>
            </div>
            <div class="box bg-gray-lightest">
              ● &nbsp;
              <a
                href="https://shanejix.com/posts/%E7%8E%B0%E4%BB%A3%20JavaScript%20%E6%95%99%E7%A8%8B%20%E2%80%94%20JavaScript%20%E7%BC%96%E7%A8%8B%E8%AF%AD%E8%A8%80%E7%AF%87/"
                target="_blank"
                class="text-gray-dark"
              >
                《 The modern JavaScript leak detection - the JavaScript language 》
              </a>
            </div>

          </div>
        </section>

        {/* 工作经历 */}
        <section>
          <h1>
            工作经历
          </h1>
          <div class="boxes">
            <div class="box bg-gray-lightest">
              中国**网络股份有限责任公司（ 2019 年 11 月 ~ 至今 ）
            </div>
            <div class="box bg-gray-lightest">
              <p class="m-t-0">1. OM 电商平台官网建设与更新、维护</p>
              <p>
                <p>● 业务方案的设计与评审、迭代与上线后的持续维护与功能扩展；从用户角度出发、来验证业务逻辑、并添加相应细节交互、提示等 </p>
                <p>● 团队配合成功完成网站一周一次的功能迭代和部署、参与技术客服及时对技术问题予以支持 </p>
                <p>● 使用 React + React Hook  + TypeScript 进行页面重构和功能开发、优化前端组件与模块化、完成了核心页面的功能升级和重构维护 </p>
                <p>● 制定前端规则、React 组件规则、Lint 规则、优化 JavaScript 代码架构和开发规范 </p>
                <p>● 将公共组件与非业务模块等进行封装、孵化出了 A**-ui 基础组件库；并且在开发过程中不断完善、进而在各个项目和团队中得以复用、极大提升了开发效率 </p>
                <p>● 定期组织内部分享与 code review、包括 Git 的使用、ES6+ 新特性、TypeScript 等话题 </p>
              </p>
            </div>
            <div class="box bg-gray-lightest">
              <p class="m-t-0">2. A**-ui 组件库</p>
              <p>
                <p>● 主导 A**-ui 的代码规范、目录设计规范、参与讨论组件库的架构设计；负责部分复杂交互组件的开发、单元测试、文档编写、code review</p>
                <p>● 使用 Webpack 及 Gulp 等构建工具、实现了组件库的全量引入和按需引入</p>
                <p>● 部分组件已被公司同事广泛使用、并成为了公司级别的 UI 组件库、有效提高了前端团队的开发效率</p>
              </p>
            </div>
            <div class="box bg-gray-lightest">
              <p class="m-t-0">3. 物料平台</p>
              <p>
                <p>● 基于来自 A**-ui 组件库的技术沉淀、创作与管理定制化组件与页面、服务于内部多个前端技术团队、有效增强团队协作、促进了资源共享、提升了开发效率</p>
                <p>● 参与讨论物料平台的架构设计包括前端站点、CLI 工具、后端搭建；主要负责 CLI 项目初始化、物料模板的本地开发测试与使用、CLI 部分指令的开发</p>
              </p>
            </div>
          </div>

          <div class="boxes">
            <div class="box bg-gray-lightest">
              成都**科技有限公司 （ 2019 年 6 月 ~ 2019 年 10 月 ）
            </div>
            <div class="box bg-gray-lightest">
              <p class="m-t-0">1. OC 后台管理系统</p>
              <p>
                <p>● 使用 React 全家桶重构原生 JavaScript 项目 </p>
                <p>● 优化并改善现有的前端代码结构、提高代码的可读性与可维护性、制定合理有效的开发规范 </p>
              </p>
            </div>
          </div>
        </section>

        {/* 自我评价 */}
        <section>
          <h1>自我评价</h1>
          <div class="boxes">
            <div class="box bg-gray-lightest">
              ● 对前端技术具有浓厚的兴趣、具备良好的审美能力、有良好的代码编程习惯
            </div>
            <div class="box bg-gray-lightest">
              ● 长期活跃于 GitHub、不断学习并贡献内容
            </div>
            <div class="box bg-gray-lightest">
              ● 想成为一个优秀的全栈
            </div>
            <div class="box bg-gray-lightest">
              ● 工作之余酷爱登山和摄影、希望未来能成为一个户外摄影师
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`